/* @import url(//fonts.googleapis.com/css?family=Vibur); */
/* html,body{
  height:100%
} */
/* body {
  background: #112 url(//images.weserv.nl/?url=i.imgur.com/6QJjYMe.jpg)  center no-repeat;
  background-size:cover;
  margin:0
} */
.logo {
  text-align: center;
  /* width: 65%; */
  /* height: 250px; */
  margin: auto;
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
    user-select: none;
}

.logo b{
    font-size: 3rem !important;
  font: 400 19vh "Vibur";
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444, 0 10px 3px #000;
}
.logo b span{
  animation: blink linear infinite 2s;
}
.logo b span:nth-of-type(2){
  animation: blink linear infinite 3s;
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}


/* follow me @nodws */
#btn-twtr{
  clear:both;
  color:#fff;
  border:2px solid;
  border-radius:3px;
  text-align:center;
  text-decoration:none;
  display:block;
  font-family:sans-serif;
  font-size:14px;
  width:13em;
  padding:5px 10px;
  font-weight:600;
  /* position:absolute; */
  bottom:20px;
  left:0;
  right:0;
  margin:0 auto;
  /* background:rgba(0,0,0,0.2); */
  box-shadow:0 0 0px 3px rgba(0,0,0,0.2);
  opacity:0.4
}#btn-twtr:hover{color:#fff;opacity:1}


@media screen and (max-width: 600px) {
    .logo b{
        font-size: 2rem !important;
    }
}





/* Emoji Spin */
/* html, body {
  margin: 0;
  height: 100%;
  background: #7f7fd5;
  background: -webkit-linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4);
  background: linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4);
  font-family: 'helvetica', sans-serif;
} */

.container {
  display: grid;
  place-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: white !important; */
  background-color: #181C30  !important;
  background-image: none !important;
  margin: 0 auto !important;
  width: 90%;
}

.spinner {
  position: relative;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0px 5px 7px -2px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

/* .spinner__won {
  position: relative;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0px 5px 7px -2px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
} */

.spinner {
  /* max-width: 610px; */
  /* min-width: 610px; */
  border-top: 5px solid black;
  border-bottom: 5px solid black;
}

.spinner__won {
  /* max-width: 590px; */
  /* min-width: 590px; */
  /* padding: 3px 10px; */
  /* font-size: 24px; */
  letter-spacing: 12px;
}

.spinner-items {
  /* position: relative; */
  display: inline-flex;
  margin: 0;
  padding: 0;
  /* margin-left: -246px; */
}

.spinner__marker {
  position: absolute;
  height: 100%;
  width: 3px;
  background-color: yellow;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
}

.spinner-items__item {
  display: block;
  list-style-type: none;
  padding: 32px 0;
  font-size: 32px;
  color: #c2c2c2;
  border-left: 5px solid black;
  width: 80px;
  /* max-width: 117px;
  overflow: hidden; */
  text-align: center;
}

.spin-button {
    margin-top: 40px;
  padding: 21px 46px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 5px 7px -2px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  background-color: white;
  transition: box-shadow 300ms ease;
}

.spin-button:hover {
  box-shadow: 0px 2.5px 3.5px -1px rgba(0, 0, 0, 0.2);
}

.win {
  background-color: lemonchiffon;
  margin: auto;
}


@keyframes spinMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Adjust this value based on your needs */
  }
}

.spinner-moving {
  animation: spinMove 2s linear infinite; /* Adjust time and other properties as needed */
}

.canvasss {
  min-width: 1300px !important;
  margin: auto !important;
  
}