@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Tenor+Sans&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap');


.fortunes4-logo {
  font-family: "Protest Strike", sans-serif;
  font-weight: 300;
  font-style: normal;
}


h1, h2 {
  font-family: "Archivo Black", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h3, h4, h5, h6, p, span, div, ul {
  font-family: "Tenor Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}