.neon {
  color: #fff;
  text-shadow:
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 20px #fff,
    0 0 40px #0ff,
    0 0 80px #0ff,
    0 0 90px #0ff,
    0 0 100px #0ff,
    0 0 150px #0ff;
    text-align: center;
}


/* general styling */
/* :root {
  font-size: calc(1vw + 1vh + 1.5vmin);
}

html, body {
  height: 100%;
  margin: 0;
} */

/* .container {
} */

.container {
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #010a00;
  background-image: url("https://www.transparenttextures.com/patterns/3px-tile.png");
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 2rem;
  text-align: center;
  width: 400px;
  margin-bottom: 40px;
}

h1 {
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 450px) {
  .container {
    width: 300px;
    font-size: 1.5rem;
  }
}